html {
  font-size: 14px !important;
}
.limiter1 {
  width: 100%;
  margin: 0 auto;
}
.edit__input {
  height: 53px !important;
  font-size: 14px;
}
.container-login101 {
  width: 500px;
}
img.imgClass {
  filter: drop-shadow(0.35rem 0.35rem 0.4rem #f34c559a);
}
.login101-form-title {
  display: block;
  font-family: 'Times New Roman', Times, serif;
  color: red;
  font-size: 30px;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 26px;
}
.p-b-48 {
  padding-bottom: 18px;
}
.wrap-input101 {
  padding: 0 42px;
}
.container-login101-form-btn {
  padding: 26px 42px;
  width: 100%;
}
.container-login101-form-btn .login101-form-btn {
  padding: 12px 20px;
  width: 100%;
  height: auto;
  color: white;
  font-size: 20px;
  font-family: 'Times New Roman', Times, serif;
  border-radius: 15px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
}
.container-login101-form-btn .login101-form-btn:hover {
  box-shadow: 0px 15px 20px rgba(100, 102, 101, 0.4);
}
/* ROM */
.room {
  width: 100%;
  margin: 0 auto;
}
.container-room {
  width: 550px;
}
.MuiFormLabel-root {
  font-size: 1.2rem !important;
}
.MuiInputBase-input {
  font-size: 14px !important;
}
/* screen-call */
.screen-call {
  width: 100%;
}
.screen-call .screen-call__left {
  margin-top: 50px;
}
.screen-call .screen-call__right {
  width: 800px;
  min-height: 300px;
  border: 2px solid #ccc;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px;
  background-color: #ffffffba;
}
.right__code-title {
  padding: 0 78px 0 30px;
}
.right__serial-title {
  padding: 0 132px 0 30px;
}
.right__type-item {
  border-left: 2px solid #ccc;
}
.dl-inline {
  display: inline-block;
}
.p-70 {
  padding: 0 72px 0 30px;
}
.bd {
  border-bottom: 2px solid #ccc;
}
.text-style h3 {
  font-size: 40px !important;
  font-weight: 600;
}
.text-style-item h2 {
  font-size: 40px !important;
  font-size: 600 !important;
  margin-left: 50px;
}
.bd-left {
  border-left: 2px solid #ccc;
}
.h-100 {
  height: 100px !important;
}
/* Button  */
.screen-call .button-cancel {
  float: right !important;
}
.screen-call-container .btn-call {
  text-align: center;
  margin-top: 50px;
}
.screen-call-container .btn-call .btn {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 2px;
  padding: 10px 12px;
}
/* CSS ROOM  */
.w-800 {
  width: 800px;
}
.bd-l-3 {
  border-right: 3px solid black;
}
.mt-50 {
  margin-top: 50px;
}
.f-rigth {
  float: right;
}
.w-2 {
  width: 18%;
}
.h-2 {
  height: auto;
}
.mg-5 {
  margin-right: 50px;
}
.mg-items {
  font-size: 9.5rem !important;
  margin: 40px 50px;
}
.mr-135 {
  margin-right: 135px;
}
.mr-100 {
  margin-right: 100px;
}
.ml-100 {
  margin-left: 100px;
}
.ml-135 {
  margin-left: 135px;
}
.mg-l-5 {
  margin-left: 50px;
}
.room_name,
.room_date {
  font-size: 30px;
  color: red;
  line-height: 1.2;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bolder;
}
.title-left h3 {
  color: red;
  font-weight: bolder;
  font-size: 30px;
  list-style: 1.2;
}
.title-right h3 {
  color: #000;
  font-weight: bolder;
  font-size: 30px;
  list-style: 1.2;
}
.items h3 {
  font-weight: bolder;
}
.font-stye {
  font-size: 20px !important;
  font-weight: bolder;
}
.text-color-red {
  color: red;
}
.text-color-info {
  color: #66cc00;
}
.btn-font {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.2;
  transition: 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.18) !important;
}
.table-right-body-banner h3 {
  width: 100%;
  height: 39px;
  display: inline-block;
  padding: 8px 12px;
  border-radius: 2px;
  background-color: #66cc00;
  color: #fff;
  font-size: 12px !important;
  font-weight: 600;
  line-height: 2.2;
  vertical-align: middle;
  touch-action: manipulation;
  white-space: nowrap;
  margin-top: 12px;
  margin-bottom: 16px;
  cursor: pointer;
}
.table-content {
  height: 430px;
}
.my-custom-scrollbar {
  overflow: auto;
}
.my-custom-scrollbar::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}
.TM {
  padding-left: 8px;
  padding-right: 18px;
  margin-top: 0px;
}
.QT {
  padding-left: 10px;
  padding-right: 15px;
  margin-top: 0px;
}
